import React, { useState, useEffect } from 'react';
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import BtnSecondary from "../../components/layout/buttons/secondary/BtnSecondary"
import BtnDefault from "../../components/layout/buttons/default/BtnDefault"
import { Alert, Col, Container, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import {BsArrowRight, BsExclamationTriangle, BsFillExclamationTriangleFill, BsInfoCircle} from 'react-icons/bs';
import loadingLogo from '../../assets/loading/loading.gif';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import "../../styles/Table.sass"
import { MultiSelect } from "react-multi-select-component";

//Styles
import './Create.sass'
import styles from './Create.module.sass'
import '../../styles/global.sass'
import '../../styles/variables.sass'
import ImageUploader1 from "../../components/forms/ImageUploader/ImageUploader"
import moment from 'moment';
import axios, { AxiosInstance } from 'axios';
import AlertDanger from '../../components/Alerts/AlertDanger/AlertDanger';
import { useLocation } from 'react-router-dom';
import AlertWarning from '../../components/Alerts/AlertWarning/AlertWarning';
import AlertInfo from '../../components/Alerts/AlertInfo/AlertInfo';
import AlertInfoRejected from '../../components/Alerts/AlertInfoRejected/AlertInfoRejected';
import CreateProps from './CreateProps';
import ImageUploaderMultiple from '../../components/forms/ImageUploaderMultiple/ImageUploaderMultiple';

function Create(props: CreateProps) {

    let { state } = useLocation();

    const [data, setData] = useState([] as any);
    const [loading, setLoading] = useState(0);  
    const [submitting, setSubmitting] = useState(0);   
    const [documentFile, setDocumentFile] = useState([] as any);
    const [annexes, setAnnexes] = useState([] as any);
    const [signers, setSigners] = useState([] as any);
    const [error, setError] = useState("");
    const [formData, setFormData] = useState(null as any);
    const [show, setShow] = useState(false);
    const [showExtra, setShowExtra] = useState(false);
    const [showErrorFormat, setShowErrorFormat] = useState(false);
    const [proximsButlletinsAlert, setProximsButlletinsAlert] = useState("");
    const [proximsButlletinsWarning, setProximsButlletinsWarning] = useState("");
    const [characterCounter, setCharacterCounter] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseExtra = () => setShowExtra(false);
    const handleShowExtra = () => setShowExtra(true);
    const handleCloseErrorFormat = () => setShowErrorFormat(false);
    const countCharacters = (event: any) => setCharacterCounter(event.target.value.length);

    useEffect(() => {
        props.apiService.GetTaxonomyFields()
        .then((response: any) => {
            if(response.data.proximsButlletins.length > 0)
            {
                let nextButlletins = response.data.proximsButlletins.sort((a: any, b: any) => {
                    return new Date(a.proximsButlletins).getTime() - new Date(b.proximsButlletins).getTime()
                }).reverse();
                if(moment().diff(moment(nextButlletins[0].dataTancament), 'minutes') > 0)
                {
                    setProximsButlletinsAlert("El termini per publicar al BOPA ordinari ha finalitzat. Aquest document es publicarà al BOPA de la setmana vinent.");
                }
                else
                {
                    setProximsButlletinsWarning("El termini per publicar al BOPA ordinari finalitza a les " + moment(nextButlletins[0].dataTancament).format('HH:mm'));
                }                        
            }
            setData(response.data);
            
            if(state != null && (state.id != null || state.editId != null)) {
                let id: number;
                if (state.id != null)
                {
                    id = state.id;
                }
                else 
                {
                    id = state.editId;
                }
                props.apiService.GetDocumentById(id)
                .then ((result: any) => {
                    setFormData(result.data);
                    setAnnexes(result.data.annexes);
                    setDocumentFile([result.data.document]);
                    result.data.signatories
                    .map((signer: any )=> {
                        setSigners((oldsigners: any) => [...oldsigners, {value: signer.id, label: signer.value}])
                    })
                    setLoading(1);
                })
                .catch ((error: any) => {
                    console.log(error)
                })
            } else {
                setLoading(1); 
            }
        })
        .catch((error: any) => {
            console.log(error)
        })
    }, []);

    const readFileDataAsBase64 = (e: any) => {
        const file = e;
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = (event: any) => {
                resolve(event.target.result.split(';base64,')[1]);
            };        
            reader.onerror = (err) => {      
                reject(err);      
            };   
            reader.readAsDataURL(file);
        });
    }
    
    const readAnnexesArrayDataAsBase64 = (fileArray: any[]) => {
        let files = fileArray.filter((file: any) => file != null)
        let annexes : any[] = [];
        return new Promise((resolve) => {
            Promise.all(
            files.map((file: any) => {    
                if(file != null && file.fileUrl != null)
                {
                    return annexes.push( {
                        fileName: file.fileName,
                        fileArray: file.fileUrl
                    } );
                }
                else {
                    return readFileDataAsBase64(file).then((res:any) => {
                        annexes.push(
                            {
                                fileName: file.name,
                                fileArray: res
                            }
                        );
                    });
                }           
            }))
            .then(() => {
                resolve(annexes);
            });
        });
    }

    const readDocumentArrayDataAsBase64 = (files: any[]) => {
        let doc : any = {fileName: "", fileArray:""};
        return new Promise((resolve) => {
            Promise.all(
                files.map((file: any) => {
                if(file != null && file.fileUrl != null)
                {
                    doc.fileName = file.name;
                    doc.fileArray = file.fileUrl;
                    return doc;
                }
                else {
                    return readFileDataAsBase64(file).then((res:any) => {
                        doc.fileName = file.name;
                        doc.fileArray= res;
                    });
                }                        
            }))
            .then(() => {
                resolve(doc);
            });
        });
    }
    
    
    const handleSubmit  = (event: any) => {
        if(event.nativeEvent.submitter.name  == "SendButton")
        {
            setSubmitting(2);
        } else {
            setSubmitting(1);
        }
        event.preventDefault();

        var checkBox = document.getElementsByName("paperSignature") as any;
        if(characterCounter > 256) {
            setError("El sumari no pot tenir més de 256 caràcters.");
            setSubmitting(0);
            window.scrollTo(0, 0);
        }
        else if(signers.length == 0 && checkBox[0].checked == false) {
            setError("S'han d'informar els signataris");
            setSubmitting(0);
            window.scrollTo(0, 0);
        }
        else if(documentFile == null || documentFile.length == 0) {
            setError("S'ha d'afegir el cos del document");
            setSubmitting(0);
            window.scrollTo(0, 0);
        } else {
            var serialize = require('form-serialize');
            var form = document.querySelector('#addDocument');
            var obj = serialize(form, { hash: true });
            obj.Signatories = signers;
            obj.Name = moment().format("YYYY_MM_DD_h_mm_ss");
            readDocumentArrayDataAsBase64(documentFile)
            .then((response1: any) => {
                obj.Document = response1;
                if(annexes.length > 0) {
                    readAnnexesArrayDataAsBase64(annexes)
                    .then((response2: any) => {
                        obj.Annexes = response2;
                        submit(event.nativeEvent.submitter.name, obj);
                    })
                }
                else {
                    submit(event.nativeEvent.submitter.name, obj);
                }
            })
        }        
    } 

    const submit = (name: any, obj: any) => {
        if(state != null && state.id != null) {
            obj.id = state.id
        }
        else if(state != null && state.editId != null) {
            obj.rectifyId = state.editId
        }
        if(name == "SaveButton") {
            props.apiService.CreateDraft(obj)
            .then((response: any) => {
                if(response.status != null && response.status == 200) {
                    window.open('/Esborrany', '_self');
                } else {
                    if(response.statusText != null){
                        setError("Error: " + response.statusText);
                        setSubmitting(0);
                        window.scrollTo(0, 0);
                        console.log(JSON.stringify(obj));
                    }
                    else if (response != null) {
                        setError("Error: " + response.data);
                        setSubmitting(0);
                        window.scrollTo(0, 0);
                        console.log(JSON.stringify(obj));
                    }
                    else {
                        setError("Error");
                        setSubmitting(0);
                        window.scrollTo(0, 0);
                        console.log(JSON.stringify(obj));
                    }
                }
            })
            .catch((error: any) => {
                console.log(JSON.stringify(obj));
                setError("Error: " + error);
                setSubmitting(0);
                window.scrollTo(0, 0);
            })
        }
        else if(name == "SendButton") {
            obj.sent = true;
            props.apiService.CreateDraft(obj)
            .then((response: any) => {
                if(response.status != null && response.status == 200) {
                    window.open('/EnCurs', '_self');
                } else {
                    if(response.statusText != null){
                        setError("Error: " + response.statusText);
                        window.scrollTo(0, 0);
                        console.log(JSON.stringify(obj));
                    }
                    else if (response != null) {
                        setError("Error: " + response.data);
                        window.scrollTo(0, 0);
                        console.log(JSON.stringify(obj));
                    }
                    else {
                        setError("Error");
                        window.scrollTo(0, 0);
                        console.log(JSON.stringify(obj));
                    }
                }
            })
            .catch((error: any) => {
                console.log(JSON.stringify(obj));
                setError("Error: " + error);
                window.scrollTo(0, 0);
            })
        }
    }

    const handleChangeSelectedDocument = (event: any) => {  
        console.log(event.target.files[0])
        if(event.target.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
        {
            setDocumentFile([event.target.files[0]]);
        } else {
            setShowErrorFormat(true);
        }
    } 

    const handleChangeRemoveDocumentNew = () => { 
        setDocumentFile([]);
    } 

    const handleChangeSelectedAnnex = (event: any) => {
        let filesAux: any[] = [];
        for (let i = 0; i < event.target.files.length; i++) {
            if(event.target.files[i].type == "application/pdf")
            {
                filesAux.push(event.target.files[i])
            } else {
                setShowErrorFormat(true);
            }  
            if(i == event.target.files.length - 1) {
                if(annexes.length == 0) {
                    setAnnexes(filesAux);
                }
                else {
                    setAnnexes((oldArray: any[]) => [...oldArray, ...filesAux]);
                }
            }
        }   
    } 

    const handleChangeRemoveAnnexNew = (event: any) => {
        const file = event.name
        setAnnexes(annexes.filter((item: any) => item.name !== file));
    } 

    if(loading != 0){
        return (  
        <Container>
        <div className={styles.titleContainer}>
            <Col><div className='h2'>{state != null && state.id != null ? "Editar document" : "Nou document" }</div></Col>
            <Col>
            </Col>                
            <Col></Col>
        </div>
        { error != "" ? <AlertDanger message={error}/> : null }
        { proximsButlletinsAlert != "" ? <AlertDanger message={proximsButlletinsAlert}/> : null}
        { proximsButlletinsWarning != "" ? <AlertWarning message={proximsButlletinsWarning}/> : null}

        { state != null && state.editId != null ? 
        <div>
            <AlertWarning message={"Es crearan un document i un nom nous perquè pugueu corregir l'error i enviar-lo de nou."}/>
            <AlertInfoRejected message={"Motiu de rebuig: "} htmlValue={formData.rejectReason}/>
        </div> : null }
        <div className={styles.contentCompBG}>
            <div className='p1'>Dades del document</div>
            <hr />
                <Form id='addDocument' onSubmit={handleSubmit} >
                    <Row>
                        <Col>
                            <Form.Label>Codi intern del document
                            </Form.Label>
                                <Form.Control
                                    name="internalCode"
                                    className={styles.inputMiddle}
                                    type="text"
                                    placeholder="Inserir..."
                                    defaultValue={formData != null ? formData.internalCode : null}
                            />
                            <p></p>
                        </Col>
                        <Col>
                            <Form.Label>Tipus de document
                            <span className={styles.required}>*</span>
                            </Form.Label>
                            <Form.Select 
                                name="documentType" 
                                className={styles.inputMiddle} 
                                defaultValue={formData != null ? formData.documentType.id : null}
                                required>
                                    <option value="">Seleccionar </option>
                                    {
                                        data.tipusDocument
                                        .sort((a: any, b: any) => a.value > b.value ? 1 : -1)
                                        .map((item: any) => {
                                            return (
                                                <option value={item.id}>{item.value}</option>
                                            )
                                        })
                                    }
                            </Form.Select>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Sumari<span className={styles.required}>*</span></Form.Label>
                            <Form.Control 
                                defaultValue={formData != null ? formData.summary : null}
                                name="summary"
                                as="textarea" 
                                rows={3} 
                                onChange={countCharacters}
                                className={styles.inputMiddle} 
                                placeholder='Inserir...'
                                required />
                            <span>{characterCounter + '/256 caràcters'}</span>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        <Form.Label>Cos del document
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 250 }}
                                overlay={   <Tooltip id="button-tooltip" >
                                            Formats suportats: .doc i .docx
                                            </Tooltip>
                                        }
                                >
                                <span className={styles.icHelp}><BsInfoCircle /></span>
                            </OverlayTrigger>
                            <span className={styles.required}>*</span>
                        </Form.Label>
                        <ImageUploader1 
                            changeSelected={handleChangeSelectedDocument} 
                            removeFile={handleChangeRemoveDocumentNew}
                            selectedFile={documentFile}></ImageUploader1>
                        <p></p>

                        </Col>
                        <Col>

                        <Form.Label>Annexes
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 250 }}
                                overlay={   <Tooltip id="button-tooltip" >
                                            Formats suportats: .pdf
                                            </Tooltip>
                                        }>
                                <span className={styles.icHelp}><BsInfoCircle /></span>
                            </OverlayTrigger>
                        </Form.Label>
                        <ImageUploaderMultiple
                            changeSelected={handleChangeSelectedAnnex} 
                            removeFile={handleChangeRemoveAnnexNew}
                            selectedFile={annexes}></ImageUploaderMultiple>
                        <p></p>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Data del document
                                <span className={styles.required}>*</span>
                            </Form.Label>
                            <Form.Control 
                            name="documentDate"
                            className={styles.inputSmall}
                            type="date"
                            defaultValue={ formData != null ? moment(formData.documentDate).format('yyyy-MM-DD') : "" }
                            required >
                            </Form.Control>
                            <p></p>
                        </Col>
                        <Col>
                        {/* <Form.Label>Lloc document
                                <span className={styles.required}>*</span>
                            </Form.Label>
                            <Form.Select 
                            defaultValue={formData != null ? formData.documentPlace.id : null}
                            name="documentPlace"
                            className={styles.inputSmall}
                            required >
                                <option value="">Seleccionar</option>
                                {
                                    data.llocSignatura.map((item: any) => {
                                        return (
                                            <option value={item.id}>{item.value}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                            <p></p> */}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label>Organisme
                                <span className={styles.required}>*</span>
                            </Form.Label>
                            <Form.Select 
                            defaultValue={formData != null ? formData.organism.id : null}
                            name="organism"
                            className={styles.inputMiddle}
                            required >
                                <option value="">Seleccionar</option>
                                {
                                    data.organismes
                                    .sort((a: any, b: any) => a.value > b.value ? 1 : -1)
                                    .map((item: any) => {
                                        return (
                                            <option value={item.id}>{item.value}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                            <p></p>
                        </Col>
                        <Col>
                            <Form.Label>Tema
                                <span className={styles.required}>*</span>
                            </Form.Label>
                            <Form.Select 
                            defaultValue={formData != null ? formData.issue.id : null}
                            name="issue"
                            className={styles.inputMiddle}
                            required >
                                <option value="">Seleccionar</option>
                                {
                                    data.temes
                                    .sort((a: any, b: any) => a.value > b.value ? 1 : -1)
                                    .map((item: any) => {
                                        return (
                                            <option value={item.id}>{item.value}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                            <p></p>

                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label>Firmants del document
                                <span className={styles.required}>*</span>
                            </Form.Label>
                            <MultiSelect
                                options={
                                    data.signataris
                                    .sort((a: any, b: any) => a.value > b.value ? 1 : -1)
                                    .map((item: any) => {
                                        return ({
                                            value: item.id, 
                                            label: item.value,
                                        })
                                    })
                                }
                                className={styles.inputMiddle}
                                value={signers}
                                onChange={setSigners}
                                labelledBy="signers"
                                overrideStrings={{
                                    "allItemsAreSelected": "Tots els firmants",
                                    "clearSearch": "Netejar cerca",
                                    "clearSelected": "Netejar selecció",
                                    "noOptions": "No hi ha opcions",
                                    "search": "Cerca",
                                    "selectAll": "Seleccionar tots",
                                    "selectAllFiltered": "Seleccionar tots (Filtrats)",
                                    "selectSomeItems": "Seleccionar",
                                    "create": "Crear",
                                }}
                            />
                            <p></p>
                        </Col>
                        <Col className={styles.checkBoxContainer}>
                                <Row>
                                    <Col>
                                        <Form.Check
                                            name="paperSignature"
                                            label={"Firma en paper"}
                                            defaultChecked={formData != null && formData.paperSignature != null ? formData.paperSignature : false}
                                        />
                                    </Col>
                                    
                                    <Col>
                                    <Form.Check
                                        onChange={(event: any) => { if(event.target.checked == true) { handleShowExtra()}} }
                                        name="ExtraBOPA"
                                        label={"BOPA extra"}
                                        defaultChecked={formData != null && formData.extraBOPA != null ? formData.extraBOPA : false}
                                    />
                                    </Col>
                                </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label>Document de referència</Form.Label>
                                <Form.Control
                                    defaultValue={formData != null ? formData.referenceDocument : null}
                                    name="referenceDocument"
                                    className={styles.inputMiddle}
                                    type="text"
                                    placeholder="Inserir..."
                            />
                            <p></p>
                        </Col>
                        <Col>
                            <Form.Label>Nombre de dies d'exposició pública
                            </Form.Label>
                                <Form.Control
                                defaultValue={formData != null ? formData.expositionDays : null}
                                name="expositionDays"
                                type="number"
                                placeholder="Inserir..."
                                min={0}
                                className={styles.inputSmall}
                            />
                            <p></p>
                        </Col>
                    </Row>                        
                    <Form.Label>Comentaris</Form.Label>
                    <Form.Control 
                        defaultValue={formData != null ? formData.comments : null}
                        name="comments"
                        as="textarea" 
                        rows={3} 
                        className={styles.inputMiddle} 
                        placeholder='Inserir...' />
                    <p></p>

                    <Form.Label>Codi CELEX
                    </Form.Label>
                        <Form.Control
                            defaultValue={formData != null  ? formData.celexCode : null}
                            name="CELEXCode"
                            className={styles.inputSmall}
                            type="text"
                            placeholder="Inserir..."
                    />
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Tornar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AlertWarning message="Les dades introduïdes es perdran. Vols Tornar?" />
                        </Modal.Body>
                        <Modal.Footer>
                            <BtnPrimary text="Tornar" iconRight={<BsArrowRight />} onClick={ () => { 
                                if(state != null && state.id != null ) {
                                    window.open("/Esborrany","_self")
                                }
                                else if (state != null && state.editId != null) {
                                    window.open("/Denegats","_self")
                                }
                                else {
                                    window.open("/","_self")
                                }
                             }} />
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showExtra} onHide={handleCloseExtra}>
                        <Modal.Header closeButton>
                            <Modal.Title>BOPA Extra</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='alert alert-warning d-flex align-items-center'>
                                <BsFillExclamationTriangleFill size={"35px"} />
                                <div className='ml-1' style={{paddingLeft: "10px"}}>
                                    {"Per publicar un BOPA Extra s'haurà d'informar previament al BOPA. Aquest servei té un cost adicional."}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showErrorFormat} onHide={handleCloseErrorFormat}>
                        <Modal.Header closeButton>
                            <Modal.Title>Format incorrecte</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                <div className='alert alert-danger d-flex align-items-center' role="alert">
                                <BsExclamationTriangle />
                                <div className='ml-1' style={{paddingLeft: "10px"}}>
                                    {"Format d'arxiu no suportat."}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <hr />
                    <div className={styles.containerBtns}>
                        <div className={styles.buttonCustom}>
                            <BtnDefault onClick={handleShow} text={"Tornar"}></BtnDefault>
                        </div>
                        <div className={styles.containerBtns2}>
                            <div className={styles.buttonCustom}>
                                <BtnSecondary type="submit" name={"SaveButton"} text={"Desar esborrany"} disabled={submitting != 0 ? true : false} loading={submitting == 1 ? true : false} ></BtnSecondary>
                            </div>
                            <div className={styles.buttonCustom}>
                                <BtnPrimary type="submit" name={"SendButton"} text={"Enviar ara"} iconRight={<BsArrowRight />} disabled={submitting != 0  ? true : false} loading={submitting == 2 ? true : false} ></BtnPrimary> 
                            </div>
                        </div>
                    </div>  
                </Form>
            </div>
        </Container> 
    )
    }
    else {
        return (
            <Container>
            <div className={styles.titleContainer}>
                <Col><div className='h2'>{state != null && state.id != null ? "Editar document" : "Nou document" }</div></Col>
                <Col></Col>                
                <Col></Col>
            </div>
            <div className={styles.contentCompBG}>
                <div className="app-spinner">
                        <img width={150} src={loadingLogo} alt="loading..." />
                    </div>
            </div>
            </Container> 
        );
    }
}

export default Create;